import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link, useNavigate } from 'react-router-dom';

function Categorias() {
  const [menuData, setMenuData] = useState(null);
  const navigate = useNavigate();
  const languageset = localStorage.getItem('language')
  
  useEffect(() => {
      if (!languageset) {
        navigate('/menu-linguagens')
      }
  },[navigate, languageset])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`../../data_${languageset}.json`);
        const data = await response.json();
        await setMenuData(data);
        // console.log('data')
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };
    fetchData();
  },[languageset]);


  const removeAccents = str =>
      str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  return (
    <div>
      <Header />
      <div className="container mx-auto esquina-bg pb-5">
        <h1 className='text-white esquina-heading p-10'>Menu</h1>
          <ul className='flex flex-wrap flex-row'>
            {menuData ? (
              menuData.menu_categories.map((item,index) => (
                <li className='w-6/12 md:w-4/12 px-2' key={index}>
                  <Link className="no-underline" to={`/menu-categorias/${removeAccents(item.category_name)}`}>
                    <div className="py-10 my-2 border-4 border-[#33525a] rounded-xl">
                      <p className='text-white mb-4 text-center no-underline'><b>{item.category_name}</b></p>
                    </div>
                  </Link>
                </li>
              ))
              ) : (
                <div>
                  <p className="text-white">Loading data...</p>
                </div>
                )}
          </ul>
      </div>
      <Footer />
    </div>
  );
}

export default Categorias;
