import React from 'react'
import { useState, useEffect } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer';
import { Link, useNavigate } from 'react-router-dom';

const Subcategorias = () => {
    const [lastUrlPart, setLastUrlPart] = useState(null);
    const [menuData, setMenuData] = useState(null);
    const navigate = useNavigate();
    const languageset = localStorage.getItem('language')
  
    useEffect(() => {
        if (!languageset) {
          navigate('/menu-linguagens')
        }
    },[navigate, languageset])

    useEffect(() => {
        const url = new URL(window.location.href);
        const pathParts = url.pathname.split('/').filter(part => part !== ''); // Remove empty parts
        const lastPart = pathParts[pathParts.length - 1];
        setLastUrlPart(lastPart);
    }, []);

    useEffect(() => {
    const fetchData = async () => {
        try {
        const response = await fetch(`../../data_${languageset}.json`);
        const data = await response.json();
        setMenuData(data);
        } catch (error) {
        // console.error('Error fetching data:', error);
        }
    };
    fetchData();
    },[languageset]);

    const removeAccents = str =>
        str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    return (
        <>
        <Header />
        <div className="container mx-auto esquina-bg pb-5">
            <div className='text-white esquina-heading p-10'>{lastUrlPart}</div>
            {menuData ? (
                menuData.menu_categories.map((category, index) => {
                if (category.subcategory) {
                    return (
                        <ul className='flex flex-wrap flex-row' key={index}>
                        {category.subcategory.map((item, itemIndex) => (
                            <li className="w-6/12 md:w-4/12 px-2" key={itemIndex}>
                                <Link to={`/menu-categorias/${languageset === 'en' ? 'Drinks' : 'Bebidas'}/${removeAccents(item.subcategory_name)}`}>
                                    <div className="py-10 my-2 border-4 border-[#33525a] rounded-xl">
                                        <p className='text-white mb-4 text-center no-underline'><b>{item.subcategory_name === 'Aguas' ? 'Águas' : item.subcategory_name}</b></p>
                                    </div>
                                </Link>
                            </li>
                        ))}
                        </ul>
                    );
                } else {
                    return null; // Don't render anything if category_name doesn't match
                }
                })
            ) : (
                <p className="text-white">Loading data...</p>
            )}
        </div>
        <Footer />
        </>
    )
}

export default Subcategorias