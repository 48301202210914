import Footer from "../components/Footer";
import Logo from "../assets/logo.png";

const Home = () => {

  return (
    <>
      <div className="home-bg">
        <img className="py-20 mx-auto home-img" src={Logo} alt="" />
        <h2 className="text-white py-4 font-bold text-center">geral@laesquinarestaurante.com</h2>
        <h1 className="text-white pt-4 pb-20 font-bold text-center">Rua Dom João V N 4, 2820-179 Aroeira</h1>
      </div>
      <Footer />
    </>
  );
}

export default Home;
