import React from 'react'
import { useState, useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useNavigate } from 'react-router'

const SubcategoriaItems = () => {
  const [lastUrlPart, setLastUrlPart] = useState(null);
  const [menuData, setMenuData] = useState(null);
  const navigate = useNavigate();
  const languageset = localStorage.getItem('language')
  
  useEffect(() => {
      if (!languageset) {
        navigate('/menu-linguagens')
      }
  },[navigate, languageset])

  useEffect(() => {
      const url = new URL(window.location.href);
      const pathParts = url.pathname.split('/').filter(part => part !== ''); // Remove empty parts
      const lastPart = pathParts[pathParts.length - 1];
      setLastUrlPart(lastPart);
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`../../data_${languageset}.json`);
        const data = await response.json();
        setMenuData(data);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };
    fetchData();
  },[languageset]);

  return (
    <>
      <Header />
        <div className="pb-5 esquina-bg">
          <div className="container mx-auto">
            <div className='text-white esquina-heading p-10'>{lastUrlPart === 'Aguas' ? 'Águas' : lastUrlPart}</div>
              {menuData ? (
              menuData.menu_categories.map((category, index) => {
                if (category.category_name === 'Bebidas' || category.category_name === 'Drinks') {
                  return (
                    <div key={index}>
                      {category.subcategory.map((subcategory, subItemIndex) => {
                        if (subcategory.subcategory_name === lastUrlPart) {
                          // console.log(lastUrlPart)
                          return (
                            <ul className='flex flex-wrap flex-row' key={subItemIndex}>
                              {subcategory.items.map((item, itemIndex) => (
                                <li className="w-full md:w-6/12 lg:w-4/12 px-2" key={itemIndex}>
                                    <div className="p-5 my-4 border-2 border-b-8 border-[#33525a] rounded-xl">
                                      <p className='item-name-heading text-white mb-4 text-5xl'><b>{item.item_name}</b></p>
                                      <p className='text-white text-6xl font-bold text-right mt-10'>{item.price.toFixed(2)}€</p>
                                    </div>
                                </li>
                              ))}
                            </ul>
                          )
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  );
                } else {
                  return null; // Don't render anything if category_name doesn't match
                }
              })
                ) : (
                    <p>Loading data...</p>
                )}
            </div>
        </div>
      <Footer />
    </>
  )
}

export default SubcategoriaItems