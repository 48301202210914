import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PTFlag from '../assets/pt_flag.png'
import ENFlag from '../assets/en_flag.png'
import { Link } from 'react-router-dom'

const Linguagens = () => {
    const setLocalStorageLanguage = (language) => {
        // if (language === 'delete_language') {
        //     localStorage.removeItem('language');
        // } else {
            localStorage.setItem("language", language);
        // }
    };

    // setLocalStorageLanguage('delete_language');

  return (
    <>
        <Header />
        <div className="container mx-auto text-center">
            <div className="flex flex-col flex-wrap justify-center content-center esquina-bg">
                <h1 className='my-4 text-white esquina-language'>
                    Linguagem/Language
                </h1>
                <Link to={`/menu-categorias/`} onClick={() => {setLocalStorageLanguage('en')}}>
                    <img className="mx-auto my-2 w-32 md:w-48 lg:w-64 lg:my-10 rounded-full border-4 border-[#4cadc9]" src={ENFlag} alt="English Flag" />
                </Link>
                <Link to={`/menu-categorias/`} onClick={() => {setLocalStorageLanguage('pt')}}>
                    <img className="mx-auto my-2 w-32 md:w-48 lg:w-64 lg:my-10 rounded-full border-4 border-[#4cadc9]" src={PTFlag} alt="Portugal Flag" />
                </Link>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Linguagens