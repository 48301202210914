import React from 'react'
import background from '../assets/background_header.png'
import Logo from '../assets/logo.png'

const Header = () => {
  return (
      <div style={{backgroundImage: `url(${background})`}}>
            <div className="container mx-auto">
            <img className="max-h-96 mx-auto" src={`${Logo}`} alt="" />
        </div>
    </div>
  )
}

export default Header