import React from 'react'

const Footer = () => {
  return (
    <div className='w-full bg-black p-10'>
        <p className='text-white text-center'>
        Copyright © 2023 La Esquina - Authentic Taqueria
        </p>
    </div>
  )
}

export default Footer