import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import Categorias from "./pages/Categorias";
import CategoriaItems from "./pages/CategoriaItems";
import Subcategorias from "./pages/Subcategorias";
import SubcategoriaItems from './pages/SubcategoriaItems';
import Linguagens from './pages/Linguagens';

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/menu-categorias/" element={<Categorias />} />
          <Route exact path="/menu-linguagens/" element={<Linguagens />} />
          <Route exact path="/menu-categorias/:categoriaitems" element={<CategoriaItems />} />
          <Route exact path="/menu-categorias/Bebidas" element={<Subcategorias />} />
          <Route exact path="/menu-categorias/Bebidas/:categoriaitems" element={<SubcategoriaItems />} />
          <Route exact path="/menu-categorias/Drinks" element={<Subcategorias />} />
          <Route exact path="/menu-categorias/Drinks/:categoriaitems" element={<SubcategoriaItems />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
