import React from 'react'
import { useState, useEffect } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer';
import Spicy1 from '../assets/malagueta-level-1.png'
import Spicy2 from '../assets/malagueta-level-2.png'
import Spicy3 from '../assets/malagueta-level-3.png'
import Leaf from '../assets/folha.png'
import { useNavigate } from 'react-router';

const CategoriaItems = () => {
    const [lastUrlPart, setLastUrlPart] = useState(null);
    const [menuData, setMenuData] = useState(null);
    const navigate = useNavigate();
    const languageset = localStorage.getItem('language')
  
    useEffect(() => {
        if (!languageset) {
          navigate('/menu-linguagens')
        }
    },[navigate, languageset])

    const getLanguage = () => {
        return localStorage.getItem('language');
      }

    useEffect(() => {
        const url = new URL(window.location.href);
        const pathParts = url.pathname.split('/').filter(part => part !== ''); // Remove empty parts
        const lastPart = pathParts[pathParts.length - 1];
        setLastUrlPart(lastPart);
    }, []);


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`../../data_${getLanguage()}.json`);
            const data = await response.json();
            setMenuData(data);
          } catch (error) {
            // console.error('Error fetching data:', error);
          }
        };
        fetchData();
      },[languageset]);
  

    return (
        <>
            <Header />
            <div className="container mx-auto pb-5 esquina-bg">
                <div className='text-white esquina-heading p-10'>{lastUrlPart}</div>
                {menuData ? (
                    menuData.menu_categories.map((category, index) => {
                    if (category.category_name === lastUrlPart) {
                        return (
                            <ul className='flex flex-wrap flex-row' key={index}>
                            {category.items.map((item, itemIndex) => (
                                <li className="w-full lg:w-6/12 xl:w-4/12 px-2" key={itemIndex}>
                                    <div className="p-5 my-4 border-2 border-b-8 border-[#33525a] rounded-xl">
                                        <p className='item-name-heading text-white text-5xl'><b>{item.item_name}</b></p>
                                        <p className='text-white description text-base'>{item.description}</p>
                                        {item.sauce ? (
                                            <p className='text-white description text-base mt-3'><b>Molho:</b> {item.sauce}</p>      
                                        ) : (
                                            null
                                        )}
                                        <div className={`flex flex-row pt-10 items-center ${item.spicy ? 'justify-between' : 'justify-end'} spices-img`}>
                                            {item.spicy === 1 ? (
                                                <img src={Spicy1} alt={`Spicy Level ${item.spicy}`} />
                                            ) : null}
                                            {item.spicy === 2 ? (
                                                <img src={Spicy2} alt={`Spicy Level ${item.spicy}`} />
                                            ) : null}
                                            {item.spicy === 3 ? (
                                                <img src={Spicy3} alt={`Spicy Level ${item.spicy}`} />
                                            ) : null}
                                            {item.veggie ? (
                                                <img className="mx-8"src={Leaf} alt={`Spicy Level ${item.spicy}`} />
                                            ) : null}
                                            <p className='text-white text-6xl font-bold'>{item.price.toFixed(2)}€</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                            </ul>
                        );
                    } else {
                        return null; // Don't render anything if category_name doesn't match
                    }
                    })
                ) : (
                    <p className="text-white">Loading data...</p>
                )}
            </div>
            <Footer />
        </>
    )
}

export default CategoriaItems